// import logo from '../../../../assets/images/common/logo.webp'
import header_bg from '../../../../assets/images/common/header_img.webp'
import sqr from '../../../../assets/images/common/square2.png'

import c from '../header.module.scss'
import { Link } from 'react-router-dom'
import { Logo } from '../../../supportingElements/Logo'

const HeaderTop = ({ variant }) => {
    return (
        <div className={c.header_top}>
            <div className='header__container'>
                <div className={c.top_row1}>
                    <div className={c.left}>
                        {variant == 1 ? (
                            <div className={c.logoWr}>
                                <Logo color='#093462'/>
                                {/* <img src={logo} alt='ЭКМ ТРЕЙД' /> */}
                            </div>
                        ) : (
                            <Link to='/' className={c.logoWr}>
                                <Logo color='#093462'/>
                                {/* <img src={logo} alt='ЭКМ ТРЕЙД' /> */}
                            </Link>
                        )}
                    </div>

                    <div className={c.right}>
                        <div className={c.topSector}>
                            <img src={sqr} alt='' />
                        </div>
                        <p className={c.paragraph_v1}>
                            Оператор поставок электротехнической продукции для <br />
                            предприятий промышленности, гражданского строительства, <br />
                            машиностроения, транспортной инфраструктуры
                        </p>
                        <p className={c.paragraph_v2}>
                            Оператор поставок электротехнической продукции ООО НПП «ИНТЕХ» и ООО «ЭКМ Холдинг»
                        </p>
                    </div>
                </div>

                <div className={c.top_row2}>
                    <div className={c.left}>
                        <div className={c.topSector}>
                            <img src={sqr} alt='' />
                        </div>
                        <p>
                            Компания является <br />
                            официальным дилером <br />
                            ООО НПП «ИНТЕХ» и <br />
                            ООО «ЭКМ Холдинг»
                        </p>
                    </div>

                    <div
                        className={c.right}
                        // style={{ background: `#fff center / contain no-repeat url("${header_bg}")` }}
                    >
                        <div className={c.imgBgWr}>
                            <img src={header_bg} alt='' />
                        </div>
                        <p className={c.subtitle}>ООО «ЭКМ Трейд»</p>
                        <p className={c.info}>
                            ИНН: 0274164586
                            <br />
                            КПП: 027601001
                            <br />
                            ОКПО: 384789900
                            <br />
                            ОКТМО: 80701000001
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { HeaderTop }
