import bg2 from '../../../assets/images/common/bg2.webp'

import { HeaderTop } from './parts/HeaderTop'
import { HeaderBottom } from './parts/HeaderBottom'

import c from './header.module.scss'


const Header = ({ headerVariant }) => {

    const headerStyle = headerVariant == 2
        ? {
            backgroundColor: '#000',
            backgroundImage: `url('${bg2}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover'
        } : {}


    return (
        <header className={`${c.header} ${headerVariant == 2 ? c.blackHeader : ''}`} style={headerStyle}>
            <HeaderTop variant={headerVariant} />
            <HeaderBottom variant={headerVariant} />
        </header>
    )
}

export { Header }