import React from 'react'
import shortid from 'shortid'
import { ArrowBarRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

import cpp from '../../../assets/images/catalog/main_cat/1.webp'
import cns from '../../../assets/images/catalog/main_cat/2.webp'

import c from './products_main.module.scss'



const mainCats = [
    {
        title: 'Кабельно-проводниковая продукция',
        image: cpp,
        link: 'catalog/cpp'
    },
    {
        title: 'Кабеленесущие системы',
        image: cns,
        link: 'catalog/cns'
    },
]

const CatBlockJsx = ({ title, image, link }) => {
    return (
        <Link to={link} className={`${c.category} effect_on_hover`}>
            <div className={c.coverWr}>
                <img src={image} alt={title} />
            </div>
            <div className={c.nameWr}>
                <h3 className={c.name}>
                    {title}
                </h3>
                <ArrowBarRight />
            </div>
        </Link>
    )
}

const ProductsMain = ({ hash }) => {
    const prodRef = React.useRef()
    if (hash == '#production') {
        prodRef?.current?.scrollIntoView({
            behavior: 'smooth', block: 'end', alignToTop: true})
        }

    
    return (
            <section className={`${c.production} section dsection`} id='production' ref={prodRef}>
                <div className='prod__container'>
                    <h2>Продукция</h2>

                    <div className={c.categories}>

                        {mainCats.map(cat => <CatBlockJsx key={shortid.generate()} title={cat.title} image={cat.image} link={cat.link} />)}

                    </div>
                </div>
            </section>
        )
    }

    export { ProductsMain }