import { ArrowBarRight } from 'react-bootstrap-icons'
import { Link as LinkRR } from 'react-router-dom'

import c from './articles.module.scss'



const ArticlePreview = ({ name, cover, date, content, link }) => {
    return (
        <LinkRR to={`/news/${link}`} className={`${c.article} effect_on_hover`}>
            <div className={c.imgWr}>
                <img src={cover} alt={name} />
            </div>
            <div className={c.artText}>
                <p className={c.date}>{date}</p>
                <h3 className={c.artName}>{name}</h3>

            </div>
            <ArrowBarRight />
        </LinkRR>
    )
}

export { ArticlePreview }