import bg1 from '../../../assets/images/common/bg1.webp'
import bg2 from '../../../assets/images/common/bg2.webp'
//import bg2_z from '../../../assets/images/common/bg2-z.webp'
//import bg3 from '../../../assets/images/common/bg3.webp'
//import bg3_z from '../../../assets/images/common/bg3-z.webp'
//import bg4 from '../../../assets/images/common/bg4.webp'
import bg4_z from '../../../assets/images/common/bg4-z.webp'

import c from './banner.module.scss'

const Banner = () => {
    return (
        <section className={`${c.banner}`} style={{
            backgroundColor: '#13385F',
            backgroundImage: `url('${bg2}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover'
        }}>
            <div className='banner__container'>
                <div className={c.bannerContent}>
                    <h1>Поставка кабельно-проводниковой продукции и кабеленесущих систем</h1>
                </div>
            </div>
        </section>
    )
}

export { Banner }