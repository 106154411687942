import { Contacts } from './contacts/Contacts'
import { About } from './aboutUs/About'
import { Banner } from './banner/Banner'
import { ProductsMain } from './products/ProductsMain'
import { Sertificates } from './sertificates/Sertificates'
import { NewsBlock } from './news/NewsBlock'


const MainPage = ({ hash, articlesData }) => {
    return (
        <>
            <Banner />
            <About hash={hash} />
            <ProductsMain hash={hash} />
            <Sertificates hash={hash} />
            <NewsBlock hash={hash} articlesData={articlesData} />
            <Contacts hash={hash} />

        </>
    )
}

export { MainPage }