import { Link as LinkRR } from 'react-router-dom'
import c from './articles.module.scss'

const ArticlePage = ({ name, cover, date, content }) => {
    return (
        <div className={`article__container ${c.container}`}>
            <ul className={c.breadcrumbs}>
                <li>
                    <LinkRR to='/'>Главная</LinkRR>
                </li>
                <li>
                    <LinkRR to='/news'>Новости</LinkRR>
                </li>
                <li>
                    <span>{name}</span>
                </li>
            </ul>
            <div className={c.articlePageContent}>
                <div className={c.coverWr}>
                    <img src={cover} alt={name} />
                </div>

                <p className={c.date}>{date}</p>

                <h1 className={c.title}>{name}</h1>

                <div className={c.artText} dangerouslySetInnerHTML={{ __html: content }} />
                {/* {content.split('~').map(pr=><p>{pr}</p>)}
                </div> */}
            </div>
        </div>
    )
}

export { ArticlePage }
