import React from 'react'
import { Link } from 'react-router-dom'
import { CatalogCard } from './CatalogCard'
import shortid from 'shortid'
import { BoxArrowUpRight, X } from 'react-bootstrap-icons'
import nophoto from '../../assets/images/common/nophoto.png'
import c from './catalog.module.scss'


//  если type links , то формат items такой  
// items = {
//     'Крепеж метрический': {
//         cover: "/static/media/1.0bf84c79179c9a175110.webp",
//         linkHere: "Krepezh-metricheskij"
//     },
//     'Крепеж метрический': {
//         cover: "/static/media/1.0bf84c79179c9a175110.webp",
//         linkHere: "Krepezh-metricheskij"
//     },
// }

//  если type modals , то формат items такой  
// items = {
//     Аксессуары: {
//            linkHere: 'aksessuary', 
//            cover: '/static/media/3.07f842524ed45de3cf4f.webp', 
//            linkEcabel: 'https://ek-m.com/catalog/aksessuary', 
//            description: 'Крышки, углы, заглушки, пластины заземления и др.'
//      },
//     'Крепеж метрический': {
//            linkHere: 'aksessuary', 
//            cover: '/static/media/3.07f842524ed45de3cf4f.webp', 
//            linkEcabel: 'https://ek-m.com/catalog/aksessuary', 
//            description: 'Крышки, углы, заглушки, пластины заземления и др.'
//     },
// }



const Catalog = ({ type, items, previousLinks, lett }) => {

    const [modalWindData, setModalWindData] = React.useState()
    const [isModalWindOpen, setIsModalWindOpen] = React.useState(false)

    const onClickCardHandler = (info) => {
        setModalWindData(info)
        setIsModalWindOpen(true)
        // console.log(modalWindData)
        document.body.classList.add('lock')
    }

    const closeModalHandler = (e) => {
        if (!e.target.closest(`.${c.modalWindContent}`) || e.target.closest(`.${c.close}`)){
            setIsModalWindOpen(false)
            document.body.classList.remove('lock')
        }
    }

    const breadCrumbsJSX = Object.entries(previousLinks).map(el => {
        return <li key={shortid.generate()}>
            <Link to={el[1]}>{el[0]}</Link>
        </li>
    })

    let cardsJSX
    if (type === 'links') {
        cardsJSX = Object.entries(items).map(el => {
            return <CatalogCard key={shortid.generate()} type='link' info={{
                name: el[0],
                cover: el[1].cover,
                linkHere: el[1].linkHere,
                linkEcabel: null,
                description: null
            }} />
        })
    } else if (type === 'modals') {
        cardsJSX = Object.entries(items).map(el => {
            return <CatalogCard key={shortid.generate()} type='modal' info={{
                name: el[0],
                cover: el[1].cover,
                linkHere: el[1].linkHere,
                linkEcabel: el[1].linkEcabel,
                description: el[1].description
            }} onClickFunc={onClickCardHandler} />
        })
    }

    return (
        <>
            <div className={`${c.modalWind} ${isModalWindOpen ? c.open : ''}`} onClick={closeModalHandler}>
                <div className={c.modalWindContent}>
                    <div className={c.close}>
                        <X />
                    </div>
                    <h2 className={c.nameModal}> {modalWindData?.name}</h2>

                    {/* <div className={c.modalTextImg}> */}
                    <div className={c.modalImgWr}>
                        <img src={modalWindData?.cover ? modalWindData?.cover : nophoto} />
                    </div>
                    <div className={c.modalDesc}>
                        {modalWindData?.description.split('~').map(par => <p className={c.paragraph} key={shortid.generate()}>{par}</p>)}
                    </div>

                    {/* <a className={c.moreInfo} href={modalWindData?.linkEcabel} target='_blank'>
                        <span>Больше харектеристик</span>
                        <BoxArrowUpRight />
                    </a> */}


                </div>
            </div>
            <div className={`catalog__container ${c.container}`} >
                <h1 className={c.title}>{lett}</h1>
                <ul className={c.breadcrumbs}>
                    {breadCrumbsJSX}
                    <li>
                        <span>{lett}</span>
                    </li>
                </ul>
                <div className={c.catalogItems}>
                    {cardsJSX}
                </div>
            </div >
        </>

    )
}

export { Catalog }