import shortid from 'shortid'
import c from './articles.module.scss'
import { ArticlePreview } from './ArticlePreview'
import { useSearchParams } from 'react-router-dom'
import { Paginator } from '../supportingElements/paginator/Paginator'
import React from 'react'

const PAGE_SIZE = 8

const ArticlesPage = ({ articlesData }) => {
   const [searchParams, setSearchParams] = useSearchParams()
   const pageParam = searchParams.get('page')
   let activePage = parseInt(String(pageParam)) || 1

   const totalPagesCount = Math.ceil(articlesData.length / PAGE_SIZE)

   const [dataPortionForPage, setDataPortionForPage] = React.useState([])

   const onChangePageFunc = pageNum => {
      const firstIdx = (pageNum - 1) * PAGE_SIZE
      const lastIdx = Math.min(firstIdx + PAGE_SIZE - 1, articlesData.length - 1)

      const arrData = []
      for (let i = firstIdx; i <= lastIdx; i++) {
         arrData.push(articlesData[i])
      }

      setDataPortionForPage(arrData)
   }

   React.useEffect(() => {
      if (totalPagesCount < activePage) {
         setSearchParams({})
         onChangePageFunc(1)
      } else {
         onChangePageFunc(activePage)
      }
   }, [])

   const articlesJSX = dataPortionForPage.map(art => <ArticlePreview key={shortid.generate()} name={art.name} cover={art.cover} date={art.date} content={art.content} link={art.link} />)
   return (
      <div className={`articles__container ${c.container}`}>
         <h1 className={c.title}>Новости</h1>

         <div className={c.articles}>{articlesJSX}</div>
         <div className={c.paginationBlock}>
            <Paginator onPageChangeHandler={onChangePageFunc} totalRecordsCount={articlesData.length} pageLimit={PAGE_SIZE} baseURI={'/' + 'news' + '?'} activePage={activePage} />
         </div>
      </div>
   )
}

export { ArticlesPage }
