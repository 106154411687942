import React from 'react'

const LoginPage = () => {
   React.useEffect(() => {
    //   try {
         fetch('/php/test.php', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({ login: 'testLogin' }),
         })
             .then(res => res.text())
            .then(res => console.log(res))

         
    //   } catch (err) {
    //      alert('Ошибка сервера(')
    //   }
   }, [])
   return <>LoginPage</>
}

export { LoginPage }
