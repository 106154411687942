import { Link } from 'react-router-dom'
import c from './pnf.module.scss'
import { ArrowBarLeft } from 'react-bootstrap-icons'

const Page404 = () => {
    return (
        <section className={`${c.pnf} section`} >
            <div className='pnf__container'>
                <h2>Ошибка 404, страница не найдена</h2>

                <p>
                    <Link to='/'>
                        <ArrowBarLeft/>
                        <span>Вернуться на главную</span>
                        
                    </Link>
                </p>

            </div>
        </section>
    )

}

export { Page404 }