// Монтажный кабель ИнСил®
import mont1 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/1.webp'
import mont2 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/2.webp'
import mont3 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/3.webp'
import mont4 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/4.webp'
import mont5 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/5.webp'
import mont6 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/6.webp'
import mont7 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/7.webp'
import mont8 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/8.webp'
import mont9 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/9.webp'
import mont10 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/10.webp'
import mont11 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/11.webp'
import mont12 from '../../assets/images/catalog/kabelno_prov_prod/montazh_kabel/12.webp'

// Кабели силовые ИнСил®  0,66 - 3 кВ
import sil_066_3_1 from '../../assets/images/catalog/kabelno_prov_prod/silovye_066_3/1.webp'
import sil_066_3_2 from '../../assets/images/catalog/kabelno_prov_prod/silovye_066_3/2.webp'
import sil_066_3_3 from '../../assets/images/catalog/kabelno_prov_prod/silovye_066_3/3.webp'
import sil_066_3_4 from '../../assets/images/catalog/kabelno_prov_prod/silovye_066_3/4.webp'
import sil_066_3_5 from '../../assets/images/catalog/kabelno_prov_prod/silovye_066_3/5.webp'
import sil_066_3_6 from '../../assets/images/catalog/kabelno_prov_prod/silovye_066_3/6.webp'

// Кабели силовые ИнСил®  6 - 35 кВ
import sil_6_35_1 from '../../assets/images/catalog/kabelno_prov_prod/silovye_6_35/1.webp'
import sil_6_35_2 from '../../assets/images/catalog/kabelno_prov_prod/silovye_6_35/2.webp'
import sil_6_35_3 from '../../assets/images/catalog/kabelno_prov_prod/silovye_6_35/3.webp'

// Монтажные кабели КуПе® 
import kupe1 from '../../assets/images/catalog/kabelno_prov_prod/kupe/1.webp'
import kupe2 from '../../assets/images/catalog/kabelno_prov_prod/kupe/2.webp'
import kupe3 from '../../assets/images/catalog/kabelno_prov_prod/kupe/3.webp'
import kupe4 from '../../assets/images/catalog/kabelno_prov_prod/kupe/4.webp'
import kupe5 from '../../assets/images/catalog/kabelno_prov_prod/kupe/5.webp'
import kupe6 from '../../assets/images/catalog/kabelno_prov_prod/kupe/6.webp'
import kupe7 from '../../assets/images/catalog/kabelno_prov_prod/kupe/7.webp'
import kupe8 from '../../assets/images/catalog/kabelno_prov_prod/kupe/8.webp'
import kupe9 from '../../assets/images/catalog/kabelno_prov_prod/kupe/9.webp'
import kupe10 from '../../assets/images/catalog/kabelno_prov_prod/kupe/10.webp'
import kupe11 from '../../assets/images/catalog/kabelno_prov_prod/kupe/11.webp'
import kupe12 from '../../assets/images/catalog/kabelno_prov_prod/kupe/12.webp'
import kupe13 from '../../assets/images/catalog/kabelno_prov_prod/kupe/13.webp'
import kupe14 from '../../assets/images/catalog/kabelno_prov_prod/kupe/14.webp'
import kupe15 from '../../assets/images/catalog/kabelno_prov_prod/kupe/15.webp'
import kupe16 from '../../assets/images/catalog/kabelno_prov_prod/kupe/16.webp'

// Провода и кабели термоэлектродные ИнСил® (Т)
import t_1 from '../../assets/images/catalog/kabelno_prov_prod/termoelectrodnye/1.webp'
import t_2 from '../../assets/images/catalog/kabelno_prov_prod/termoelectrodnye/2.webp'
import t_3 from '../../assets/images/catalog/kabelno_prov_prod/termoelectrodnye/3.webp'
import t_4 from '../../assets/images/catalog/kabelno_prov_prod/termoelectrodnye/4.webp'
import t_5 from '../../assets/images/catalog/kabelno_prov_prod/termoelectrodnye/5.webp'
import t_6 from '../../assets/images/catalog/kabelno_prov_prod/termoelectrodnye/6.webp'
import t_7 from '../../assets/images/catalog/kabelno_prov_prod/termoelectrodnye/7.webp'

// Кабели пожарные СКИНЕР® КПС
import skiner1 from '../../assets/images/catalog/kabelno_prov_prod/skiner/1.webp'
import skiner2 from '../../assets/images/catalog/kabelno_prov_prod/skiner/2.webp'
import skiner3 from '../../assets/images/catalog/kabelno_prov_prod/skiner/3.webp'
import skiner4 from '../../assets/images/catalog/kabelno_prov_prod/skiner/4.webp'
import skiner5 from '../../assets/images/catalog/kabelno_prov_prod/skiner/5.webp'
import skiner6 from '../../assets/images/catalog/kabelno_prov_prod/skiner/6.webp'
import skiner7 from '../../assets/images/catalog/kabelno_prov_prod/skiner/7.webp'
import skiner8 from '../../assets/images/catalog/kabelno_prov_prod/skiner/8.webp'
import skiner9 from '../../assets/images/catalog/kabelno_prov_prod/skiner/9.webp'
import skiner10 from '../../assets/images/catalog/kabelno_prov_prod/skiner/10.webp'
import skiner11 from '../../assets/images/catalog/kabelno_prov_prod/skiner/11.webp'
import skiner12 from '../../assets/images/catalog/kabelno_prov_prod/skiner/12.webp'
import skiner13 from '../../assets/images/catalog/kabelno_prov_prod/skiner/13.webp'
import skiner14 from '../../assets/images/catalog/kabelno_prov_prod/skiner/14.webp'
import skiner15 from '../../assets/images/catalog/kabelno_prov_prod/skiner/15.webp'
import skiner16 from '../../assets/images/catalog/kabelno_prov_prod/skiner/16.webp'

// Кабели монтажные МКПс®
import mkps1 from '../../assets/images/catalog/kabelno_prov_prod/mkps/1.webp'
import mkps2 from '../../assets/images/catalog/kabelno_prov_prod/mkps/2.webp'
import mkps3 from '../../assets/images/catalog/kabelno_prov_prod/mkps/3.webp'
import mkps4 from '../../assets/images/catalog/kabelno_prov_prod/mkps/4.webp'
import mkps5 from '../../assets/images/catalog/kabelno_prov_prod/mkps/5.webp'
import mkps6 from '../../assets/images/catalog/kabelno_prov_prod/mkps/6.webp'
import mkps7 from '../../assets/images/catalog/kabelno_prov_prod/mkps/7.webp'
import mkps8 from '../../assets/images/catalog/kabelno_prov_prod/mkps/8.webp'
import mkps9 from '../../assets/images/catalog/kabelno_prov_prod/mkps/9.webp'
import mkps10 from '../../assets/images/catalog/kabelno_prov_prod/mkps/10.webp'
import mkps11 from '../../assets/images/catalog/kabelno_prov_prod/mkps/11.webp'
import mkps12 from '../../assets/images/catalog/kabelno_prov_prod/mkps/12.webp'
import mkps13 from '../../assets/images/catalog/kabelno_prov_prod/mkps/13.webp'
import mkps14 from '../../assets/images/catalog/kabelno_prov_prod/mkps/14.webp'
import mkps15 from '../../assets/images/catalog/kabelno_prov_prod/mkps/15.webp'
import mkps16 from '../../assets/images/catalog/kabelno_prov_prod/mkps/16.webp'

// Кабели силовые с пластмассовой изоляцией
import plastmass1 from '../../assets/images/catalog/kabelno_prov_prod/s_plastmassovoy_izol/1.webp'
import plastmass2 from '../../assets/images/catalog/kabelno_prov_prod/s_plastmassovoy_izol/2.webp'
import plastmass3 from '../../assets/images/catalog/kabelno_prov_prod/s_plastmassovoy_izol/3.webp'
import plastmass4 from '../../assets/images/catalog/kabelno_prov_prod/s_plastmassovoy_izol/4.webp'
import plastmass5 from '../../assets/images/catalog/kabelno_prov_prod/s_plastmassovoy_izol/5.webp'
import plastmass6 from '../../assets/images/catalog/kabelno_prov_prod/s_plastmassovoy_izol/6.webp'



const cabProvProd = {
    'Монтажный кабель ИнСил®': {
        linkHere: 'Montazhnyj-kabel-InSil',
        cover: mont1,
        items: {
            'Кабель монтажный ИнСил-Аз': {
                linkHere: 'Kabel-montazhnyj-InSil-Az',
                cover: mont1,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-az',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»)'
            },
            'Кабель монтажный ИнСил-К': {
                linkHere: 'Kabel-montazhnyj-InSil-K',
                cover: mont2,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-k',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-Б': {
                linkHere: 'Kabel-montazhnyj-InSil-B',
                cover: mont3,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-b',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ИЭз': {
                linkHere: 'Kabel-montazhnyj-InSil-IEz',
                cover: mont4,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-iez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ИЭК': {
                linkHere: 'Kabel-montazhnyj-InSil-IEK',
                cover: mont5,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-iek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ИЭБ': {
                linkHere: 'Kabel-montazhnyj-InSil-IEB',
                cover: mont6,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-ieb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ОЭз': {
                linkHere: 'Kabel-montazhnyj-InSil-OEz',
                cover: mont7,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-oez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, с контактным проводником под экран, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ОЭК': {
                linkHere: 'Kabel-montazhnyj-InSil-OEK',
                cover: mont8,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-oek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ОЭБ': {
                linkHere: 'Kabel-montazhnyj-InSil-OEB',
                cover: mont9,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-oeb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ИЭОЭз': {
                linkHere: 'Kabel-montazhnyj-InSil-IEOEz',
                cover: mont10,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-ieoez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с общим экраном из фольгированного материала, с контактным проводником под экраном, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ИЭОЭК': {
                linkHere: 'Kabel-montazhnyj-InSil-IEOEK',
                cover: mont11,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-ieoek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
            'Кабель монтажный ИнСил-ИЭОЭБ': {
                linkHere: 'Kabel-montazhnyj-InSil-IEOEB',
                cover: mont12,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-insil-ieoeb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»).'
            },
        }
    },
    'Кабели силовые ИнСил®  0,66 - 3 кВ': {
        linkHere: 'Kabeli-silovye-Insil-066-3',
        cover: sil_066_3_1,
        items: {
            'Кабели без экрана, без брони': {
                linkHere: 'Kabeli-bez-ekrana-bez-broni',
                cover: sil_066_3_1,
                linkEcabel: 'https://ek-m.com/product/kabeli-bez-ekrana-bez-broni',
                description: 'ИнСил-ВВ, ИнСил-ПП, ИнСил-РкВ, ИнСил-РэпВ, ИнСил-РкП, ИнСил-РэпП, ИнСил-ПвВ, ИнСил-ПвП'
            },
            'Кабель силовой экранированный без брони': {
                linkHere: 'Kabel-silovoj-ekranirovannyj-bez-broni',
                cover: sil_066_3_2,
                linkEcabel: 'https://ek-m.com/product/kabel-silovoi-ekranirovannyi-bez-broni',
                description: 'ИнСил-ВВЭ, ИнСил-ППЭ, ИнСил-РкВЭ, ИнСил-РэпВЭ, ИнСил-РкПЭ, ИнСил-РэпПЭ, ИнСил-ПвВЭ, ИнСил-ПвПЭ'
            },
            'Кабели без экрана, с ленточной броней': {
                linkHere: 'Kabeli-bez-ekrana-s-lentochnoj-bronej',
                cover: sil_066_3_3,
                linkEcabel: 'https://ek-m.com/product/kabeli-bez-ekrana-s-lentochnoi-bronei',
                description: 'ИнСил-ВБВ, ИнСил-ВБШп, ИнСил-ПБП, ИнСил-РкБВ, ИнСил-РэпБВ, ИнСил-РкБП, ИнСил-РэпБП, ИнСил-ПвБВ, ИнСил-ПвБП, ИнСил-ПвБШп, ИнСил-РкБШп, ИнСил-РэпБШп'
            },
            'Кабель силовой без экрана с проволочной броней': {
                linkHere: 'Kabel-silovoj-bez-ekrana-s-provolochnoj-bronej',
                cover: sil_066_3_4,
                linkEcabel: 'https://ek-m.com/product/kabel-silovoi-bez-ekrana-s-provolochnoi-bronei',
                description: 'ИнСил-ВКВ, ИнСил-ВКШп, ИнСил-ПКП, ИнСил-РкКВ, ИнСил-РэпКВ, ИнСил-РкКП, ИнСил-РэпКП, ИнСил-ПвКВ, ИнСил-ПвКП, ИнСил-ПвКШп, ИнСил-РкКШп, ИнСил-РэпКШп'
            },
            'Кабель силовой экранированный с ленточной броней': {
                linkHere: 'Kabel-silovoj-ekranirovannyj-s-lentochnoj-bronej',
                cover: sil_066_3_5,
                linkEcabel: 'https://ek-m.com/product/kabel-silovoi-ekranirovannyi-s-lentochnoi-bronei',
                description: 'ИнСил-ПРО-ВЭБВ, ИнСил-ПРО-ВЭБШп, ИнСил-ПРО-ПЭБП, ИнСил-ПРО-РкЭБВ, ИнСил-ПРО-РэпЭБВ, ИнСил-ПРО-РкЭБП, ИнСил-ПРО-РэпЭБП, ИнСил-ПРО-ПвЭБВ, ИнСил-ПРО-ПвЭБП, ИнСил-ПРО-ПвЭБШп, ИнСил-ПРО-РкЭБШп, ИнСил-ПРО-РэпЭБШп'
            },
            'Кабель силовой экранированный с проволочной броней': {
                linkHere: 'Kabel-silovoj-ekranirovannyj-s-provolochnoj bronej',
                cover: sil_066_3_6,
                linkEcabel: 'https://ek-m.com/product/kabel-silovoi-ekranirovannyi-s-provolochnoi-bronei',
                description: 'ИнСил-ПРО-ВЭКВ, ИнСил-ПРО-ВЭКШп, ИнСил-ПРО-ПЭКП, ИнСил-ПРО-РкЭКВ, ИнСил-ПРО-РэпЭКВ, ИнСил-ПРО-РкЭКП, ИнСил-ПРО-РэпЭКП, ИнСил-ПРО-ПвЭКВ, ИнСил-ПРО-ПвЭКП, ИнСил-ПРО-ПвЭКШп, ИнСил-ПРО-РкЭКШп, ИнСил-ПРО-РэпЭКШп'
            },
        }
    },
    'Кабели силовые ИнСил®  6 - 35 кВ': {
        linkHere: 'Kabeli-silovye-Insil-6-35',
        cover: sil_6_35_1,
        items: {
            'Кабель силовой экранированный без брони': {
                linkHere: 'Kabel-silovoj-ekranirovannyj-bez-broni',
                cover: sil_6_35_1,
                linkEcabel: 'https://ek-m.com/product/kabel-silovoi-ekranirovannyi-bez-broni-1',
                description: 'ИнСил-ВВЭ, ИнСил-РэпВЭ, ИнСил-РэпПЭ, ИнСил-ПвВЭ, ИнСил-ПвПЭ, ИнСил-ППЭ'
            },
            'Кабель силовой экранированный с ленточной броней': {
                linkHere: 'Kabel-silovoj-ekranirovannyj-s-lentochnoj-bronej',
                cover: sil_6_35_2,
                linkEcabel: 'https://ek-m.com/product/kabel-silovoi-ekranirovannyi-s-lentochnoi-bronei-1',
                description: 'ИнСил -ВЭБВ, ИнСил-РэпЭБВ, ИнСил-РэпЭБП, ИнСил-ПвЭБВ, ИнСил-ПвЭБП'
            },
            'Кабель силовой экранированный с проволочной броней': {
                linkHere: 'Kabel-silovoj-ekranirovannyj-s-provolochnoj-bronej',
                cover: sil_6_35_3,
                linkEcabel: 'https://ek-m.com/product/kabel-silovoi-ekranirovannyi-s-provolochnoi-bronei-1',
                description: 'ИнСил-ВЭКВ, ИнСил-РэпЭКВ, ИнСил-РэпЭКП, ИнСил-ПвЭКВ, ИнСил-ПвЭКП'
            },
        }
    },
    'Монтажные кабели КуПе® ': {
        linkHere: 'Montazhnye-kabeli-Kupe',
        cover: kupe1,
        items: {
            'Кабель монтажный КуПе-А': {
                linkHere: 'Kabel-montazhnyj-KuPe-A',
                cover: kupe1,
                linkEcabel: 'https://ek-m.com/product/kabel-moentazhnyi-kupe-a',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, без брони.'
            },
            'Кабель монтажный КуПе-Аз': {
                linkHere: 'Kabel-montazhnyj-KuPe-Az',
                cover: kupe2,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-az',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, и любыми негигроскопичными заполнителями.'
            },
            'Кабель монтажный КуПе-К': {
                linkHere: 'Kabel-montazhnyj-KuPe-K',
                cover: kupe3,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-k',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных проволок. ~ Кабель соответствует требованиям п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-Б': {
                linkHere: 'Kabel-montazhnyj-KuPe-B',
                cover: kupe4,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-b',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных лент. Кабель соответствует требованиями п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-ИЭ': {
                linkHere: 'Kabel-montazhnyj-KuPe-IE',
                cover: kupe5,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhny-kupe-ie',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, без брони'
            },
            'Кабель монтажный КуПе-ИЭз': {
                linkHere: 'Kabel-montazhnyj-KuPe-IEz',
                cover: kupe6,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-iez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, и любыми негигроскопичными заполнителями. ~ Кабель соответствует требованиям п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14.Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-ИЭК': {
                linkHere: 'Kabel-montazhnyj-KuPe-IEK',
                cover: kupe7,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-iek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных проволок. ~ Кабель соответствует требованиям п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-ИЭБ': {
                linkHere: 'Kabel-montazhnyj-KuPe-IEB',
                cover: kupe8,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-ieb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных лент. Кабель соответствует требованиями п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-ОЭ': {
                linkHere: 'Kabel-montazhnyj-KuPe-OE',
                cover: kupe9,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-oe',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, без брони'
            },
            'Кабель монтажный КуПе-ОЭз': {
                linkHere: 'Kabel-montazhnyj-KuPe-OEz',
                cover: kupe10,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-oez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, и любыми негигроскопичными заполнителями. ~ Кабель соответствует требованиям п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-ОЭК': {
                linkHere: 'Kabel-montazhnyj-KuPe-OEK',
                cover: kupe11,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-oek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных проволок. ~ Кабель соответствует требованиям п.9.3 ГОСТ IEC 60079-14 2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-ОЭБ': {
                linkHere: 'Kabel-montazhnyj-KuPe-OEB',
                cover: kupe12,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-oeb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных лент. Кабель соответствует требованиями п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
            'Кабель монтажный КуПе-ИЭОЭБ': {
                linkHere: 'Kabel-montazhnyj-KuPe-IEOEB',
                cover: kupe13,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-ieoeb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с общим экраном из фольгированного материала, без брони'
            },
            'Кабель монтажный КуПе-ИЭОЭз': {
                linkHere: 'Kabel-montazhnyj-KuPe-IEOEz',
                cover: kupe14,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-ieoez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с общим экраном из фольгированного материала, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, и любыми негигроскопичными заполнителями. Кабель соответствует требованиям п. 9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок».'
            },
            'Кабель монтажный КуПе-ИЭОЭК': {
                linkHere: 'Kabel-montazhnyj-KuPe-IEOEK',
                cover: kupe15,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-ieoek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с общим экраном из фольгированного материала, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных проволок. Кабель соответствует требованиям п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок».'
            },
            'Кабель монтажный КуПе-ИЭОЭБ': {
                linkHere: 'Kabel-montazhnyj-KuPe-IEOEB',
                cover: kupe16,
                linkEcabel: 'https://ek-m.com/product/kabel-montazhnyi-kupe-ieoeb-1',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с общим экраном из фольгированного материала, с экструдированной поясной изоляцией (подложкой), с броней из стальных оцинкованных лент. Кабель соответствует требованиями п.9.3 ГОСТ IEC 60079-14-2013 «Взрывоопасные среды. Часть 14. Проектирование, выбор и монтаж электроустановок»'
            },
        }
    },
    'Провода и кабели термоэлектродные ИнСил® (Т)': {
        linkHere: 'Provoda-i-kabeli-termoelektrodnye-Insil-T',
        cover: t_1,
        items: {
            'Провода': {
                linkHere: 'Provoda',
                cover: t_1,
                linkEcabel: 'https://ek-m.com/product/provoda',
                description: 'Провода ИнСил(Т), предназначены для подключения термоэлектрических преобразователей (термопар) к измерительным приборам, удлинения электродов термопар, присоединения их к средствам измерения температуры или для переноса свободных концов термопар в зону с постоянной температурой, а также для изготовления термопар.'
            },
            'Кабели ИнСил(Т) без экрана, без брони': {
                linkHere: 'bez-ekrana-bez-broni',
                cover: t_2,
                linkEcabel: 'https://ek-m.com/product/kabeli-insilt-bez-ekrana-bez-broni',
                description: 'Кабели ИнСил(Т), предназначены для подключения термоэлектрических преобразователей (термопар) к измерительным приборам, удлинения электродов термопар, присоединения их к средствам измерения температуры или для переноса свободных концов термопар в зону с постоянной температурой, а также для изготовления термопар.'
            },
            'Термоэлектродные кабели ИнСил(Т) с экраном, без брони': {
                linkHere: 's-ekranom-bez-broni',
                cover: t_3,
                linkEcabel: 'https://ek-m.com/product/termoelektrodnye-kabeli-insilt-s-ekranom-bez-broni',
                description: 'Кабели ИнСил(Т), предназначены для подключения термоэлектрических преобразователей (термопар) к измерительным приборам, удлинения электродов термопар, присоединения их к средствам измерения температуры или для переноса свободных концов термопар в зону с постоянной температурой, а также для изготовления термопар.'
            },
            'Термоэлектродные кабели ИнСил(Т) без экрана, с броней из стальных оцинкованных проволок': {
                linkHere: 'bez-ekrana-s-bronej-iz-stalnyh-ocinkovannyh-provolok',
                cover: t_4,
                linkEcabel: 'https://ek-m.com/product/termoelektrodnye-kabeli-insilt-bez-ekrana-s-bronei-iz-stalnyh-ocinkovann',
                description: 'Кабели ИнСил(Т), предназначены для подключения термоэлектрических преобразователей (термопар) к измерительным приборам, удлинения электродов термопар, присоединения их к средствам измерения температуры или для переноса свободных концов термопар в зону с постоянной температурой, а также для изготовления термопар.'
            },
            'Термоэлектродные кабели ИнСил(Т) без экрана, с броней из стальных оцинкованных лент': {
                linkHere: 'bez-ekrana-s-bronej-iz-stalnyh-ocinkovannyh-lent',
                cover: t_5,
                linkEcabel: 'https://ek-m.com/product/termoelektrodnye-kabeli-insilt-bez-ekrana-s-bronei-iz-stalnyh-ocinkovann-1',
                description: 'Кабели ИнСил(Т), предназначены для подключения термоэлектрических преобразователей (термопар) к измерительным приборам, удлинения электродов термопар, присоединения их к средствам измерения температуры или для переноса свободных концов термопар в зону с постоянной температурой, а также для изготовления термопар.'
            },
            'Термоэлектродные кабели ИнСил(Т) с экраном с броней из стальных оцинкованных проволок': {
                linkHere: 's-ekranom-s-bronej-iz-stalnyh-ocinkovannyh-provolok',
                cover: t_6,
                linkEcabel: 'https://ek-m.com/product/termoelektrodnye-kabeli-insilt-s-ekranom-s-bronei-iz-stalnyh-ocinkovanny',
                description: 'Кабели ИнСил(Т), предназначены для подключения термоэлектрических преобразователей (термопар) к измерительным приборам, удлинения электродов термопар, присоединения их к средствам измерения температуры или для переноса свободных концов термопар в зону с постоянной температурой, а также для изготовления термопар'
            },
            'Термоэлектродные кабели ИнСил(Т) с экраном, с броней из стальных оцинкованных лент': {
                linkHere: 's-ekranom-s-bronej-iz-stalnyh-ocinkovannyh-lent',
                cover: t_7,
                linkEcabel: 'https://ek-m.com/product/termoelektrodnye-kabeli-insilt-s-ekranom-s-bronei-i-stalnyh-ocinkovannyh',
                description: 'Кабели ИнСил(Т), предназначены для подключения термоэлектрических преобразователей (термопар) к измерительным приборам, удлинения электродов термопар, присоединения их к средствам измерения температуры или для переноса свободных концов термопар в зону с постоянной температурой, а также для изготовления термопар'
            },
        }
    },
    'Кабели пожарные СКИНЕР® КПС': {
        linkHere: 'Kabeli-pozharnye-SKINER-KPS',
        cover: skiner1,
        items: {
            'Кабель СКИНЕР-КПСА': {
                linkHere: 'SKINER-KPSA',
                cover: skiner1,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsa',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, без брони.'
            },
            'Кабель СКИНЕР-КПСАз': {
                linkHere: 'SKINER-KPSAz',
                cover: skiner2,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsaz',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСК': {
                linkHere: 'SKINER-KPSK',
                cover: skiner3,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsk',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСБ': {
                linkHere: 'SKINER-KPSB',
                cover: skiner4,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСИЭ': {
                linkHere: 'SKINER-KPSIE',
                cover: skiner5,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsie',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, без брони.'
            },
            'Кабель СКИНЕР-КПСИЭз': {
                linkHere: 'SKINER-KPSIEz',
                cover: skiner6,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsiez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСИЭК': {
                linkHere: 'SKINER-KPSIEK',
                cover: skiner7,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsiek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСИЭБ': {
                linkHere: 'SKINER-KPSIEB',
                cover: skiner8,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsieb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСОЭ': {
                linkHere: 'SKINER-KPSOE',
                cover: skiner9,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsoe',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, без брони'
            },
            'Кабель СКИНЕР-КПСОЭз': {
                linkHere: 'SKINER-KPSOEz',
                cover: skiner10,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsoez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСОЭК': {
                linkHere: 'SKINER-KPSOEK',
                cover: skiner11,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsoek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСОЭБ': {
                linkHere: 'SKINER-KPSOEB',
                cover: skiner12,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsoeb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой под броней, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСИЭОЭ': {
                linkHere: 'SKINER-KPSIEOE',
                cover: skiner13,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsieoe',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с общим экраном из фольгированного материала, с контактным проводником под экраном, без брони.'
            },
            'Кабель СКИНЕР-КПСИЭОЭз': {
                linkHere: 'SKINER-KPSIEOEz',
                cover: skiner14,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsieoez',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с общим экраном из фольгированного материала, с контактным проводником под экраном, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСИЭОЭК': {
                linkHere: 'SKINER-KPSIEOEK',
                cover: skiner15,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsieoek',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных проволок , с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
            'Кабель СКИНЕР-КПСИЭОЭБ': {
                linkHere: 'SKINER-KPSIEOEB',
                cover: skiner16,
                linkEcabel: 'https://ek-m.com/product/kabel-skiner-kpsieoeb',
                description: 'Кабель с токопроводящими жилами из медных проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными фольгированным материалом элементами, с контактными проводниками под экранами, с общим экраном из фольгированного материала, с контактным проводником под экраном, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями.'
            },
        }
    },
    'Кабели монтажные МКПс®': {
        linkHere: 'Kabeli-montazhnye-MKPs',
        cover: mkps1,
        items: {
            'Кабели без экранов, без брони МКПсВ': {
                linkHere: 'MKPsV',
                cover: mkps1,
                linkEcabel: 'https://ek-m.com/product/kabeli-bez-ekranov-bez-broni-mkpsv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, без брони'
            },
            'Кабели без экранов, без брони МКПсВ-з': {
                linkHere: 'MKPsV-z',
                cover: mkps2,
                linkEcabel: 'https://ek-m.com/product/kabeli-bez-ekranov-bez-broni-mkpsv-z',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями'
            },
            'Кабели с проволочной броней МКПсКВ': {
                linkHere: 'MKPsKV',
                cover: mkps3,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-provolochnoi-bronei-mkpskv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичным заполнителями'
            },
            'Кабели с ленточной броней МКПсБлВ': {
                linkHere: 'MKPsBlV',
                cover: mkps4,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-lentochnoi-bronei-mkpsblv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, без экранов, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичным заполнителями (в соответствии с требованиями раздела 9.3 ГОСТ IEC 60079-14-2013)'
            },
            'Кабели с индивидуальными экранами МКПсЭИВ': {
                linkHere: 'MKPsEIV',
                cover: mkps5,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-individualnymi-ekranami-mkpseiv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, без брони.'
            },
            'Кабели с индивидуальными экранами и заполнителем МКПсЭИВ-з': {
                linkHere: 'MKPsEIV-z',
                cover: mkps6,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-individualnymi-ekranami-i-zapolnitelem-mkpseiv-z',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями раздела 9.3 ГОСТ IEC 60079-14-2013).'
            },
            'Кабели с индивидуальными экранами и проволочной броней МКПсЭИКВ': {
                linkHere: 'MKPsEIKV',
                cover: mkps7,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-individualnymi-ekranami-i-provolochnoi-bronei-mkpseikv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями раздела 9.3 ГОСТ IEC 60079-14-2013).'
            },
            'Кабели с индивидуальными экранами и ленточной броней МКПсЭИБлВ': {
                linkHere: 'MKPsEIBlV',
                cover: mkps8,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-individualnymi-ekranami-i-lentochnoi-bronei-mkpseiblv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями раздела 9.3 ГОСТ IEC 60079-14-2013).'
            },
            'Кабели с общим экраном МКПсЭВ': {
                linkHere: 'MKPsEV',
                cover: mkps9,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-obschim-ekranom-mkpsev',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном в виде оплетки из медных проволок, без брони.'
            },
            'Кабели с общим экраном и заполнителем МКПсЭВ-з': {
                linkHere: 'MKPsEV-z',
                cover: mkps10,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-obschim-ekranom-i-zapolnitelem-mkpsev-z',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном в виде оплетки из медных проволок, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, и любыми негигроскопичными заполнителями'
            },
            'Кабели с общим экраном и проволочной броней МКПсЭКВ': {
                linkHere: 'MKPsEKV',
                cover: mkps11,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-obschim-ekranom-i-provolochnoi-bronei-mkpsekv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном в виде оплетки из медных проволок, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями'
            },
            'Кабели с общим экраном и ленточной броней МКПсЭБлВ': {
                linkHere: 'MKPsEBlV',
                cover: mkps12,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-obschim-ekranom-i-lentochnoi-bronei-mkpseblv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с общим экраном в виде оплетки из медных проволок, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями'
            },
            'Кабель МКПсЭИЭВ': {
                linkHere: 'MKPsEIEV',
                cover: mkps13,
                linkEcabel: 'https://ek-m.com/product/kabel-mkpseiev',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, с общим экраном в виде оплетки из медных проволок, без брони.'
            },
            'Кабели с индивидуальным и общим экранами и заполнителем МКПсЭИЭВ-з': {
                linkHere: 'MKPsEIEV-z',
                cover: mkps14,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-individualnym-i-obschim-ekranami-i-zapolnitelem-mkpseiev-z',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, с общим экраном в виде оплетки из медных проволок, без брони, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями'
            },
            'Кабели с индивидуальным и общим экранами и проволочной броней МКПсЭИЭКВ': {
                linkHere: 'MKPsEIEKV',
                cover: mkps15,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-individualnym-i-obschim-ekranami-i-provolochnoi-bronei-mkpseiekv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, с общим экраном в виде оплетки из медных проволок, с броней из стальных оцинкованных проволок, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями'
            },
            'Кабели с индивидуальным и общим экранами и ленточной броней МКПсЭИЭБлВ': {
                linkHere: 'MKPsEIEBlV',
                cover: mkps16,
                linkEcabel: 'https://ek-m.com/product/kabeli-s-individualnym-i-obschim-ekranami-i-lentochnoi-bronei-mkpseieblv',
                description: 'Кабель с токопроводящими жилами из медных луженых проволок с изоляцией и оболочкой из полимерных материалов, соответствующих требуемому показателю пожарной опасности, с индивидуально экранированными элементами в виде оплетки из медных проволок, с общим экраном в виде оплетки из медных проволок, с броней из стальных оцинкованных лент, с круглым поперечным сечением и подложкой, полученной методом экструзии, с негигроскопичными заполнителями (в соответствии с требованиями раздела 9.3 ГОСТ IEC 60079-14-2013).'
            },
        }
    },
    'Кабели силовые с пластмассовой изоляцией': {
        linkHere: 'Kabeli-silovye-s-plastmassovoj-izolyaciej',
        cover: plastmass1,
        items: {
            'Кабели без экрана, с броней из стальных оцинкованных лент ВБШв, ПвБШв, ВБШп, ПвБШп': {
                linkHere: 'VBSHv-PvBSHv-VBSHp-PvBSHp',
                cover: plastmass1,
                linkEcabel: 'https://ek-m.com/product/bez-ekrana-vbshv-pvbshv',
                description: 'Кабели силовые с пластмассовой изоляцией без экрана, с броней из стальных оцинкованных лент, предназначены для передачи и распределения электрической энергии в стационар- ных установках на номинальное переменное напряжение 0,66; 1 и 3 кВ номинальной частоты 50 Гц, также для применения в качестве электропроводки для обеспечения электропита- ния различных устройств. Кабели силовые разработаны в соответствии с требованиями стандартов: IEC 60502-1(2009); ГОСТ 31996-2012.'
            },
            'Кабели без экрана, с броней из стальных оцинкованных проволок ВКШв, ПвКШв, ВКШп, ПвКШп': {
                linkHere: 'VKSHv-PvKSHv-VKSHp-PvKSHp-1',
                cover: plastmass2,
                linkEcabel: 'https://ek-m.com/product/bez-ekrana-vkshv-pvkshv',
                description: 'Кабели силовые с пластмассовой изоляцией без экрана, с броней из стальных оцинкованных проволок, предназначены для передачи и распределения электрической энергии в стационарных установках на номинальное переменное напряжение 0,66; 1 и 3 кВ номинальной частоты 50 Гцб также для применения в качестве электропроводки для обеспечения электропитания различных устройств. Кабели силовые разработаны в соответствии с требованиями стандартов: IEC 60502-1(2009); ГОСТ 31996-2012.'
            },
            'Кабели с экраном, с броней из стальных оцинкованных лент ВБШвЭ, ПвБШвЭ, ВБШпЭ, ПвБШпЭ': {
                linkHere: 'VBSHvE-PvBSHvE-VBSHpE-PvBSHpE',
                cover: plastmass3,
                linkEcabel: 'https://ek-m.com/product/bez-ekrana-vbshbe-pvbshpe',
                description: 'Кабели силовые с пластмассовой изоляцией с экраном, с броней из стальных оцинкованных лент, предназначены для передачи и распределения электрической энергии в стационарных установках на номинальное переменное напряжение 0,66; 1 и 3 кВ номинальной частоты 50 Гц, также для применения в качестве электропроводки для обеспечения электропитания различных устройств. Кабели силовые разработаны в соответствии с требованиями стандартов: IEC 60502-1(2009); ГОСТ 31996-2012.'
            },
            'Кабели с экраном, с броней из стальных оцинкованных лент ВКШвЭ, ПвКШвЭ, ВКШпЭ, ПвКШпЭ': {
                linkHere: 'VKSHvE-PvKSHvE-VKSHpE-PvKSHpE',
                cover: plastmass4,
                linkEcabel: 'https://ek-m.com/product/vkshve-pvkshve',
                description: 'Кабели силовые с пластмассовой изоляцией с экраном, с броней из стальных оцинкованных лент, предназначены для передачи и распределения электрической энергии в стационарных установках на номинальное переменное напряжение 0,66; 1 и 3 кВ номинальной частоты 50 Гц, также для применения в качестве электропроводки для обеспечения электропитания различных устройств. Кабели силовые разработаны в соответствии с требованиями стандартов: IEC 60502-1(2009); ГОСТ 31996-2012.'
            },
            'Кабели без экрана, без брони ВВГ, ПвВГ, ППГ, ПвПГ': {
                linkHere: 'VVG-PvVG-PPG-PvPG',
                cover: plastmass5,
                linkEcabel: 'https://ek-m.com/product/bez-ekrana-bez-brony-vvg-ppg',
                description: 'Кабели силовые с пластмассовой изоляцией без экрана, без брони, предназначены для пере- дачи и распределения электрической энергии в стационарных установках на номинальное переменное напряжение 0,66; 1 и 3 кВ номинальной частоты 50 Гц, также для применения в качестве электропроводки для обеспечения электропитания различных устройств. Кабели силовые разработаны в соответствии с требованиями стандартов: IEC 60502-1(2009); ГОСТ 31996-2012.'
            },
            'Кабели с экраном, без брони ВВГЭ, ПвВГЭ, ППГЭ, ПвПГЭ': {
                linkHere: 'VVGE-PvVGE-PPGE-PvPGE',
                cover: plastmass6,
                linkEcabel: 'https://ek-m.com/product/bez-ekrana-vvge-pvpge',
                description: 'Кабели силовые с пластмассовой изоляцией с экраном, без брони, предназначены для передачи и распределения электрической энергии в стационарных установках на номинальное переменное напряжение 0,66; 1 и 3 кВ номинальной частоты 50 Гц, также для применения в качестве электропроводки для обеспечения электропитания различных устройств.'
            },
        }
    },
}

export { cabProvProd }