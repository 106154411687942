import { Outlet } from "react-router-dom"

import { Header } from "./header/Header"
import { Footer } from "./footer/Footer"


const Layout = ({ headerVariant }) => {
    return (
        <>

            <Header headerVariant={headerVariant} />
            <main>
                <Outlet />
            </main>
            <Footer />

        </>
    )
}

export { Layout }