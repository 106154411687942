import React from 'react'
import shortid from 'shortid'
import { Link as LinkRR } from 'react-router-dom'
import { ArticlePreview } from '../../articles/ArticlePreview'
import { ArrowBarRight } from 'react-bootstrap-icons'
import c from './newsBlock.module.scss'



const NewsBlock = ({ hash, articlesData }) => {
    const newsRef = React.useRef()
    if (hash == '#news') {
        newsRef?.current?.scrollIntoView({
            behavior: 'smooth', block: 'end', alignToTop: true
        })
    }

    const articlesJSX = []

    for (let i = 0; i < articlesData.length; i++) {
        if (i > 3) break
        articlesJSX.push(
            <ArticlePreview key={shortid.generate()}
                name={articlesData[i].name} cover={articlesData[i].cover}
                date={articlesData[i].date} content={articlesData[i].content}
                link={articlesData[i].link} />
        )
    }

    
    return (
        <section className={`${c.articles} section dsection`} id='news' ref={newsRef}>
            <div className='articles__container'>
                <div className={c.titleWr}>
                    <h2>Новости</h2>
                    <LinkRR to='news'>
                        <span>Перейти в раздел</span>
                        <ArrowBarRight />
                    </LinkRR>
                </div>
                <div className={c.articles}>

                    {articlesJSX}

                </div>
            </div>
        </section>
    )
}

export { NewsBlock }