//! STRUT
import strut_cover from '../../assets/images/catalog/cabelenesychie/strut/cover.webp'

// лестничные лотки
import lest_lot1 from '../../assets/images/catalog/cabelenesychie/strut/lestnichnye_lotki/1.webp'
import lest_lot2 from '../../assets/images/catalog/cabelenesychie/strut/lestnichnye_lotki/2.webp'
// Лотки листовые
import lot_list1 from '../../assets/images/catalog/cabelenesychie/strut/lotki_listovye/1.webp'
import lot_list2 from '../../assets/images/catalog/cabelenesychie/strut/lotki_listovye/2.webp'
import lot_list3 from '../../assets/images/catalog/cabelenesychie/strut/lotki_listovye/3.webp'
//Лотки лестничные усиленные ЛХ
import usil1 from '../../assets/images/catalog/cabelenesychie/strut/lotki_usilennye/1.webp'
import usil2 from '../../assets/images/catalog/cabelenesychie/strut/lotki_usilennye/2.webp'
import usil3 from '../../assets/images/catalog/cabelenesychie/strut/lotki_usilennye/3.webp'
import usil4 from '../../assets/images/catalog/cabelenesychie/strut/lotki_usilennye/4.webp'
// Монтажные элементы
import mont1 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/1.webp'
import mont2 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/2.webp'
import mont3 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/3.webp'
import mont4 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/4.webp'
import mont5 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/5.webp'
import mont6 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/6.webp'
import mont7 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/7.webp'
import mont8 from '../../assets/images/catalog/cabelenesychie/strut/mont_el/8.webp'
// Крепеж метрический
import krep1 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/1.webp'
import krep2 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/2.webp'
import krep3 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/3.webp'
import krep4 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/4.webp'
import krep5 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/5.webp'
import krep6 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/6.webp'
import krep7 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/7.webp'
import krep8 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/8.webp'
import krep9 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/9.webp'
import krep10 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/10.webp'
import krep11 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/11.webp'
import krep12 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/12.webp'
import krep13 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/13.webp'
import krep14 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/14.webp'
import krep15 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/15.webp'
import krep16 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/16.webp'
import krep17 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/17.webp'
import krep18 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/18.webp'
import krep19 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/19.webp'
import krep20 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/20.webp'
import krep21 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/21.webp'
import krep22 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/22.webp'
import krep23 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/23.webp'
import krep24 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/24.webp'
import krep25 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/25.webp'
import krep26 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/26.webp'
import krep27 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/27.webp'
import krep28 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/28.webp'
import krep29 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/29.webp'
import krep30 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/30.webp'
import krep31 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/31.webp'
import krep32 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/32.webp'
import krep33 from '../../assets/images/catalog/cabelenesychie/strut/krep_metr/33.webp'

//! LIGHT
import light_cover from '../../assets/images/catalog/cabelenesychie/light/cover.jpg'

// Лотки листовые
import l_lot_list1 from '../../assets/images/catalog/cabelenesychie/light/lotki_list/1.webp'
import l_lot_list2 from '../../assets/images/catalog/cabelenesychie/light/lotki_list/2.webp'
import l_lot_list3 from '../../assets/images/catalog/cabelenesychie/light/lotki_list/3.webp'
// Лотки лестничные
import l_lot_lest1 from '../../assets/images/catalog/cabelenesychie/light/lotki_lest/1.webp'
import l_lot_lest2 from '../../assets/images/catalog/cabelenesychie/light/lotki_lest/2.webp'
// Короба кабельные блочные
import koroba1 from '../../assets/images/catalog/cabelenesychie/light/koroba_kab/1.webp'
import koroba2 from '../../assets/images/catalog/cabelenesychie/light/koroba_kab/2.webp'
// Монтажные элементы
import l_mont1 from '../../assets/images/catalog/cabelenesychie/light/mont_el/1.webp'
import l_mont2 from '../../assets/images/catalog/cabelenesychie/light/mont_el/2.webp'
import l_mont3 from '../../assets/images/catalog/cabelenesychie/light/mont_el/3.webp'
import l_mont4 from '../../assets/images/catalog/cabelenesychie/light/mont_el/4.webp'
import l_mont5 from '../../assets/images/catalog/cabelenesychie/light/mont_el/5.webp'
import l_mont6 from '../../assets/images/catalog/cabelenesychie/light/mont_el/6.png'

import l_mont7 from '../../assets/images/catalog/cabelenesychie/light/mont_el/7.webp'
import l_mont8 from '../../assets/images/catalog/cabelenesychie/light/mont_el/8.webp'
// Метрический крепеж
import metr_krep1 from '../../assets/images/catalog/cabelenesychie/light/metr_krep/1.webp'
import metr_krep2 from '../../assets/images/catalog/cabelenesychie/light/metr_krep/2.webp'
import metr_krep3 from '../../assets/images/catalog/cabelenesychie/light/metr_krep/3.webp'
import metr_krep4 from '../../assets/images/catalog/cabelenesychie/light/metr_krep/4.webp'
import metr_krep5 from '../../assets/images/catalog/cabelenesychie/light/metr_krep/5.webp'
import metr_krep6 from '../../assets/images/catalog/cabelenesychie/light/metr_krep/6.webp'
//caberSist.strut.items['Лестничные лотки'].items['Лестничные лотки ЛТ-02 прямые с высотой борта 50/80/100/150 мм'].description
const cabelSist = {

    'Стандарт STRUT': {
        linkHere: 'strut',
        cover: strut_cover,
        items: {
            'Лестничные лотки': {
                linkHere: 'lestnichnye-lotki',
                cover: lest_lot1,
                items: {
                    // описание img linkEcabel linkHere
                    'Аксессуары к лестничным лоткам': {
                        linkHere: 'aksessuary-k-lestnichnym-lotkam',
                        cover: lest_lot1,
                        linkEcabel: 'https://ek-m.com/catalog/aksessuary-1',
                        description: 'Углы, повороты, крышки, ответвители, заглушки.'
                    },
                    'Лестничные лотки ЛТ-02 прямые с высотой борта 50/80/100/150 мм': {
                        linkHere: 'lestnichnye-lotki-LT-02-pryamye',
                        cover: lest_lot2,
                        linkEcabel: 'https://ek-m.com/catalog/lotki-lt',
                        description: `Назначение: Построение прямых участков трасс для прокладки проводов и кабелей. ~
                        Технические характеристики: ~
                        тип замка для крышки - круглый, ~
                        тип соединения - внахлест («папа-мама»). ~
                        Исполнение: ~
                         Оцинкованная сталь по методу Сендзимира /  ~
                         Горячее цинкование /  ~
                         Нержавеющая сталь (AISI 304) / ~
                         Полимерно-порошковое покрытие`
                    }
                }
            },
            'Лотки листовые СТ': {
                linkHere: 'listovye-lotki',
                cover: lot_list1,
                items: {
                    // описание img linkEcabel linkHere
                    'Перфорированные СТП-02 с высотой борта 50/65/80/100/150 мм': {
                        linkHere: 'Perforirovannye STP-02',
                        cover: lot_list1,
                        linkEcabel: 'https://ek-m.com/catalog/perforirovannye-stp',
                        description: `Назначение: Построение прямых участков трасс для прокладки проводов и кабелей.  ~
                        Технические характеристики:  ~
                        тип замка для крышки - круглый, ~
                        тип соединения - внахлест («папа-мама»). ~
                        Исполнение:  ~
                         Оцинкованная сталь по методу Сендзимира / ~ 
                         Горячее цинкование / ~
                         Нержавеющая сталь (AISI 304) / ~
                         Полимерно-порошковое покрытие`
                    },
                    'Неперфорированные СТ-02 с высотой борта 50/65/80/100/150 мм': {
                        linkHere: 'neperforirovannye-ST-02',
                        cover: lot_list2,
                        linkEcabel: 'https://ek-m.com/catalog/neperforirovannye',
                        description: `Назначение: Построение прямых участков трасс для прокладки проводов и кабелей.  ~
                        Технические характеристики:  ~
                        тип замка для крышки - круглый, ~
                        тип соединения - внахлест («папа-мама»). ~
                        Исполнение:  ~
                         Оцинкованная сталь по методу Сендзимира /  ~
                         Горячее цинкование / ~
                         Нержавеющая сталь (AISI 304) / ~
                         Полимерно-порошковое покрытие`
                    },
                    'Аксессуары': {
                        linkHere: 'aksessuary',
                        cover: lot_list3,
                        linkEcabel: 'https://ek-m.com/catalog/aksessuary',
                        description: 'Крышки, углы, заглушки, пластины заземления и др.'
                    }
                }
            },
            'Лотки лестничные усиленные ЛХ': {
                linkHere: 'lotki-lestnichnye-usilennye-LH',
                cover: usil1,
                items: {
                    // описание img linkEcabel linkHere
                    'Лоток лестничный усиленный прямой с высотой борта 80/100/150/200': {
                        linkHere: 'lotok-lestnichnyj-usilennyj-pryamoj',
                        cover: usil1,
                        linkEcabel: 'https://ek-m.com/catalog/lotok-lestnichnyi-usilennyi',
                        description: `Назначение: Построение прямых участков трасс для прокладки проводов и кабелей.  ~
                        Технические характеристики:  ~
                        тип замка для крышки - круглый, ~
                        тип соединения - встык. ~
                        Исполнение:  ~
                         Оцинкованная сталь по методу Сендзимира /  ~
                         Горячее цинкование`
                    },
                    'Лоток лестничный усиленный прямой с перфорированным дном с высотой борта 80/100/150/200': {
                        linkHere: 'lotok-lestnichnyj-usilennyj-pryamoj-s-perforirovannym dnom',
                        cover: usil2,
                        linkEcabel: 'https://ek-m.com/catalog/s-perforirovannym-dnom',
                        description: `Назначение: Построение прямых участков трасс для прокладки проводов и кабелей.  ~
                        Технические характеристики:  ~
                        тип замка для крышки - круглый, ~
                        тип соединения - встык. ~
                        Исполнение:  ~
                         Оцинкованная сталь по методу Сендзимира /  ~
                         Горячее цинкование`
                    },
                    'Лоток лестничный усиленный прямой с неперфорированным дном с высотой борта 80/100/150/200': {
                        linkHere: 'lotok-lestnichnyj-usilennyj-pryamoj-s-neperforirovannym dnom',
                        cover: usil3,
                        linkEcabel: 'https://ek-m.com/catalog/s-neperforirovannym-dnom',
                        description: `Назначение: Построение прямых участков трасс для прокладки проводов и кабелей.  ~
                        Технические характеристики:  ~
                        тип замка для крышки - круглый, ~
                        тип соединения - встык. ~
                        Исполнение:  ~
                         Оцинкованная сталь по методу Сендзимира /  ~
                         Горячее цинкование`
                    },
                    'Аксессуары к лестничным усиленным лоткам': {
                        linkHere: 'aksessuary-k-lestnichnym-usilennym-lotkam',
                        cover: usil4,
                        linkEcabel: 'https://ek-m.com/catalog/aksessuary-usilennym-lotkam',
                        description: 'Крышки, донные вставки, повороты, держатели и др.'
                    },
                }
            },
            'Монтажные элементы': {
                linkHere: 'montazhnye-elementy',
                cover: mont1,
                items: {
                    // описание img linkEcabel linkHere
                    'Профили': {
                        linkHere: 'profili',
                        cover: mont1,
                        linkEcabel: 'https://ek-m.com/catalog/profili',
                        description: `Назначение: 
                        Установка консолей БЛ-02, БМ-02, БСЛ-02, БСМ-02 для последующей прокладки кабельных трасс с использованием кабельных лотков, кабельных коробов, узлов крепления высоковольтных кабелей.  ~
                        Технические характеристики:  ~
                        П-образный профиль, толщина металла 2 мм. ~
                        Исполнение:  ~
                         Оцинкованная сталь по методу Сендзимира /  ~
                         Горячее цинкование / ~
                         Нержавеющая сталь (AISI 304) / ~
                         Полимерно-порошковое покрытие`
                    },
                    'Консоли': {
                        linkHere: 'konsoli',
                        cover: mont2,
                        linkEcabel: 'https://ek-m.com/catalog/konsoli',
                        description: `Назначение: монтаж трассы кабельных лотков, монтаж в П-образный профиль, крепление в наклонные подвесы.  ~
                        Технические характеристики: толщина металла 2 мм. ~
                        Исполнение:  ~
                         Оцинкованная сталь по методу Сендзимира /  ~
                         Горячее цинкование / ~
                         Нержавеющая сталь (AISI 304) / ~
                         Полимерно-порошковое покрытие`
                    },
                    'Соединительные элементы': {
                        linkHere: 'soedinitelnye elementy',
                        cover: mont3,
                        linkEcabel: 'https://ek-m.com/catalog/soedinitelnye-elementy',
                        description: `Назначение: создание сложных конструкций на основе STRUT профилей.  ~
                        Технические характеристики: толщина металла 5 мм. ~
                        Исполнение: Горячее цинкование `
                    },
                    'Крепления': {
                        linkHere: 'krepleniya',
                        cover: mont4,
                        linkEcabel: 'https://ek-m.com/catalog/krepleniya',
                        description: `Назначение:  ~
                        крепление консолей к вертикальным двутавровым балкам.  ~
                        Технические характеристики:  ~
                         толщина металла 2,5мм.  ~
                        Исполнение:  ~
                        Горячее цинкование `
                    },
                    'Полоса перфорированная': {
                        linkHere: 'polosa-perforirovannaya',
                        cover: mont5,
                        linkEcabel: 'https://ek-m.com/catalog/polosa-perforirovannaya',
                        description: `Назначение:  ~
                        соединение монтажных элементов и подвес конструкций.  ~
                        Технические характеристики:  ~
                        толщина металла 1,5 или 2,5 мм. ~
                        Исполнение:  ~
                        Оцинкованная сталь по методу Сендзимира /  ~
                        Горячее цинкование / ~
                        Нержавеющая сталь (AISI 304) / ~
                        Полимерно-порошковое покрытие`
                    },
                    'Подвесы': {
                        linkHere: 'podvesy',
                        cover: mont6,
                        linkEcabel: 'https://ek-m.com/catalog/podvesy',
                        description: `Назначение:  ~
                        монтаж усиленных консолей и консолей в С-образный профиль, щдносторонний монтаж кабельных трасс, потолочное или напольное крепление.  ~
                        Технические характеристики:  ~
                        толщина профиля подвеса 2,5 мм, толщина пластины 6 мм.  ~
                        Исполнение:  ~
                        Оцинкованная сталь по методу Сендзимира /  ~
                        Горячее цинкование /  ~
                        Нержавеющая сталь (AISI 304) /  ~
                        Полимерно-порошковое покрытие`
                    },
                    'Скобы': {
                        linkHere: 'skoby',
                        cover: mont7,
                        linkEcabel: 'https://ek-m.com/catalog/skoby',
                        description: `Назначение:  ~
                        Организация подвеса кабельной трассы на шпильках к потолку.  ~
                        Технические характеристики:  ~
                        толщина металла 1,5 мм. ~
                        Исполнение:  ~
                        Оцинкованная сталь по методу Сендзимира /  ~
                        Горячее цинкование / ~
                        Нержавеющая сталь (AISI 304)`
                    },
                    'Траверса': {
                        linkHere: 'traversa',
                        cover: mont8,
                        linkEcabel: 'https://ek-m.com/catalog/traversa',
                        description: `Назначение:  ~
                        Установка кабельных полок серии Kllб для последующей прокладки кабельных трасс с использованием кабельных лотков, коробов, узлов крепления высоковольтных кабелей.  ~
                        Технические характеристики:  ~
                        толщина металла 2 мм. ~
                        Исполнение:  ~
                        Оцинкованная сталь по методу Сендзимира /  ~
                        Горячее цинкование / ~
                        Полимерно-порошковое покрытие`
                    },
                }
            },
            'Крепеж метрический': {
                linkHere: 'Krepezh-metricheskij',
                cover: krep1,
                items: {
                    // описание img linkEcabel linkHere
                    'Гайка закладная ГЗ': {
                        linkHere: 'Gajka-zakladnaya-GZ',
                        cover: krep1,
                        linkEcabel: 'https://ek-m.com/product/gaika-zakladnaya-gz',
                        description: `Назначение: ~
							 Установка кабельных полок серии Kllб для последующей прокладки кабельных трасс с использованием кабельных лотков, коробов, узлов крепления высоковольтных кабелей. ~
                        Технические характеристики: ~
								толщина металла 2 мм. ~
                        Исполнение: ~
                        Оцинкованная сталь по методу Сендзимира / 
                        Горячее цинкование /
                        Полимерно-порошковое покрытие`
                    },
                    'Винт с квадратным подголовником': {
                        linkHere: 'Vint-s-kvadratnym-podgolovnikom',
                        cover: krep2,
                        linkEcabel: 'https://ek-m.com/product/vint-s-kvadratnym-podgolovnikom',
                        description: `Назначение: ~
							 Соединение листовых или лестничных лотков между собой, крепление лотков к монтажным аксессуарам`
                    },
                    'Винт с полукруглой головкой': {
                        linkHere: 'Vint-s-polukrugloj-golovkoj',
                        cover: krep3,
                        linkEcabel: 'https://ek-m.com/product/vint-s-polukrugloi-golovkoi-2',
                        description: `Назначение: ~ 
							  Соединение монтажных элементов между собой`
                    },
                    'Болт с шестигранной головкой с частичной резьбой': {
                        linkHere: 'Bolt-s-shestigrannoj-golovkoj-s-chastichnoj-rezboj',
                        cover: krep4,
                        linkEcabel: 'https://ek-m.com/product/bolt-s-shestigrannoi-golovkoi-s-chastichnoi-rezboi-2',
                        description: `Назначение: ~ 
							  Болт М8х60 - соединение потолочных креплений СВ-02.2901, СВ-02.2902 и консолей БЛ-02.40, БМ-02.40, БСЛ-02.40, БСМ-02.40, БСЛ-02.45 с профилями ПМ-02.29, ПХ-02.29 ~
							  Болт М8х70 - соединение потолочного крепления СХИ-02.4101 с профилями ПМ-02.29, ПХ-02.29
							  `
                    },
                    'Болт с шестигранной головкой': {
                        linkHere: 'Bolt-s-shestigrannoj-golovkoj',
                        cover: krep5,
                        linkEcabel: 'https://ek-m.com/product/bolt-s-shestigrannoi-golovkoi-2',
                        description: `Назначение: ~ 
							  Соединение монтажных элементов между собой ~
							  Крепление монтажных конструкций к стенам, потолку или полу (совместно с анкерами)
							  `
                    },
                    'Шпилька резьбовая': {
                        linkHere: 'SHpilka-rezbovaya',
                        cover: krep6,
                        linkEcabel: 'https://ek-m.com/product/shpilka-rezbovaya-2',
                        description: `Назначение: ~ 
							  Длина l до 1000 мм - крепление трубных хомутов к профилям и консолям, соединение монтажных аксессуаров между собой ~
							  Длина l более 1000 мм - подвес кабеленесущих трасс к потолку
							  `
                    },
                    'Винт с шайбой для крепления конструкций к сэндвич-панелям': {
                        linkHere: 'Vint-s-shajboj-dlya-krepleniya-konstrukcij-k-sendvich-panelyam',
                        cover: krep7,
                        linkEcabel: 'https://ek-m.com/product/vint-s-shaiboi-dlya-krepleniya-konstrukcii-k-sendvich-panelyam-1',
                        description: `Назначение: ~ 
							  Крепление монтажных конструкций к сэндвич-панелям`
                    },
                    'Винт для крепления к С-образному профилю': {
                        linkHere: 'Vint-dlya-krepleniya-k-S-obraznomu-profilyu',
                        cover: krep8,
                        linkEcabel: 'https://ek-m.com/product/vint-dlya-krepleniya-k-s-obraznomu-profilyu-1',
                        description: `Назначение: ~ 
							  Крепление консолей для тяжелых нагрузок к С-образному профилю и подвесам`
                    },
                    'Гайка со стопорным буртиком': {
                        linkHere: 'Gajka-so-stopornym-burtikom',
                        cover: krep9,
                        linkEcabel: 'https://ek-m.com/product/gaika-so-stopornym-burtikom-1',
                        description: `Назначение: ~ 
							  Соединение кабельных лотков между собой ~
							  Крепление лотков к монтажным аксессуарам`
                    },
                    'Гайка шестигранная': {
                        linkHere: 'Gajka shestigrannaya',
                        cover: krep10,
                        linkEcabel: 'https://ek-m.com/product/gaika-shestigrannaya',
                        description: `Назначение: ~ 
							  Соединение кабельных лотков между собой ~
							  Крепление лотков к монтажным аксессуарам`
                    },
                    'Гайка самоконтрящаяся': {
                        linkHere: 'Gajka-samokontryashchayasya',
                        cover: krep11,
                        linkEcabel: 'https://ek-m.com/product/gaika-samokontryaschayasya-1',
                        description: `Назначение: ~ 
							  Соединение кабельных лотков между собой ~
							  Крепление лотков к монтажным аксессуарам`
                    },
                    'Гайка соединительная': {
                        linkHere: 'Gajka-soedinitelnaya',
                        cover: krep12,
                        linkEcabel: 'https://ek-m.com/product/gaika-soedinitelnaya-1',
                        description: `Назначение: ~ 
							  Соединение резьбовых шпилек между собой`
                    },
                    'Гайка STRUT': {
                        linkHere: 'Gajka-STRUT',
                        cover: krep13,
                        linkEcabel: 'https://ek-m.com/product/gaika-strut',
                        description: `Назначение: ~ 
							  Крепление консолей к С-образному профилю, подвесам и траверсам на основе С-образного профиля`
                    },
                    'Шайба плоская': {
                        linkHere: 'SHajba-ploskaya',
                        cover: krep14,
                        linkEcabel: 'https://ek-m.com/product/shaiba-ploskaya-1',
                        description: `Назначение: ~ 
							  Крепление монтажных элементов между собой`
                    },
                    'Шайба увеличенная': {
                        linkHere: 'SHajba-uvelichennaya',
                        cover: krep15,
                        linkEcabel: 'https://ek-m.com/product/shaiba-uvelichennaya-1',
                        description: `Назначение: ~ 
							   Крепление монтажных элементов между собой`
                    },
                    'Шайба гровер': {
                        linkHere: 'SHajba-grover',
                        cover: krep16,
                        linkEcabel: 'https://ek-m.com/product/shaiba-grover-1',
                        description: `Назначение: ~ 
							   Крепление монтажных элементов между собой ~ 
								Предотвращение самопроизвольного развинчивания соединений`
                    },
                    'Анкер стальной забивной': {
                        linkHere: 'Anker-stalnoj-zabivnoj',
                        cover: krep17,
                        linkEcabel: 'https://ek-m.com/product/anker-stalnoi-zabivnoi-1',
                        description: `Назначение: ~ 
							   Крепление тяжеловесных конструкций к бетону, природному камню или полнотелому кирпичу`
                    },
                    'Анкер латунный разрезной': {
                        linkHere: 'Anker-latunnyj-razreznoj',
                        cover: krep18,
                        linkEcabel: 'https://ek-m.com/product/anker-latunnyi-razreznoi-1',
                        description: `Назначение: ~ 
							   Крепление тяжеловесных конструкций к бетону, природному камню или полнотелому кирпичу`
                    },
                    'Анкер стандартный с болтом': {
                        linkHere: 'Anker-standartnyj-s-boltom',
                        cover: krep19,
                        linkEcabel: 'https://ek-m.com/product/anker-standartnyi-s-boltom-1',
                        description: `Назначение: ~ 
							   Крепление тяжеловесных конструкций к бетону, природному камню или полнотелому кирпичу`
                    },
                    'Анкер стандартный со шпилькой': {
                        linkHere: 'Anker-standartnyj-so-shpilkoj',
                        cover: krep20,
                        linkEcabel: 'https://ek-m.com/product/anker-standartnyi-so-shpilkoi-1',
                        description: `Назначение: ~ 
							   Крепление тяжеловесных конструкций к бетону, природному камню или полнотелому кирпичу`
                    },
                    'Анкер усиленный': {
                        linkHere: 'Anker-usilennyj',
                        cover: krep21,
                        linkEcabel: 'https://ek-m.com/product/anker-usilennyi-1',
                        description: `Назначение: ~ 
							   Крепление тяжеловесных конструкций к бетону, природному камню или полнотелому кирпичу`
                    },
                    'Анкер усиленный с болтом': {
                        linkHere: 'Anker-usilennyj-s-boltom',
                        cover: krep22,
                        linkEcabel: 'https://ek-m.com/product/anker-usilennyi-s-boltom',
                        description: `Назначение: ~ 
							   Крепление тяжеловесных конструкций к бетону, природному камню или полнотелому кирпичу`
                    },
                    'Анкер складной с крюком': {
                        linkHere: 'Anker-skladnoj-s-kryukom',
                        cover: krep23,
                        linkEcabel: 'https://ek-m.com/product/anker-skladnoi-s-kryukom',
                        description: `Назначение: ~ 
							   Крепление конструкций к гипсокартону, ДСП и другим тонкостенным конструкциям`
                    },
                    'Cтрубцина монтажная': {
                        linkHere: 'Ctrubcina-montazhnaya',
                        cover: krep24,
                        linkEcabel: 'https://ek-m.com/product/ctrubcina-montazhnaya',
                        description: `Назначение: ~ 
							   Вертикальное крепление шпилек к швеллеру в отсутствие возможности производить сверлильные или сварочные работы`
                    },
                    'Струбцина закрывающая': {
                        linkHere: 'Strubcina-zakryvayushchaya',
                        cover: krep25,
                        linkEcabel: 'https://ek-m.com/product/strubcina-zakryvayuschaya',
                        description: `Назначение: ~ 
							   Крепление монтажного профиля к двутавровой балке без сверлильных или сварочных работ`
                    },
                    'Дюбель для пустотелых конструкций': {
                        linkHere: 'Dyubel-dlya-pustotelyh-konstrukcij',
                        cover: krep26,
                        linkEcabel: 'https://ek-m.com/product/dyubel-dlya-pustotelyh-konstrukcii',
                        description: `Назначение: ~ 
							   Крепление конструкций к гипсокартону, ДСП и другим тонкостенным конструкциям`
                    },
                    'Крепление к профнастилу V-образное': {
                        linkHere: 'Kreplenie-k-profnastilu-V-obrazno',
                        cover: krep27,
                        linkEcabel: 'https://ek-m.com/product/kreplenie-k-profnastilu-v-obraznoe',
                        description: `Назначение: ~ 
							  Подвес шпильки к потолку из профнастила`
                    },
                    'Рым-болт': {
                        linkHere: 'Rym-bolt',
                        cover: krep28,
                        linkEcabel: 'https://ek-m.com/product/rym-bolt',
                        description: `Назначение: ~ 
							  Крепление канатов и тросов к грузам ~ 
							  Подвес и закрепление конструкций`
                    },
                    'Цепь сварная': {
                        linkHere: 'Cep-svarnaya',
                        cover: krep29,
                        linkEcabel: 'https://ek-m.com/product/cep-svarnaya',
                        description: `Назначение: ~ 
							  Подвес малонагруженных кабельных трасс`
                    },
                    'Соединитель цепей': {
                        linkHere: 'Soedinitel-cepej',
                        cover: krep30,
                        linkEcabel: 'https://ek-m.com/product/soedinitel-cepei',
                        description: `Назначение: ~ 
							 Соединение цепей между собой. Крепление цепи к перфорированным лоткам`
                    },
                    'Крюк S-образный': {
                        linkHere: 'Kryuk-S-obraznyj',
                        cover: krep31,
                        linkEcabel: 'https://ek-m.com/product/kryuk-s-obraznyi',
                        description: `Назначение: ~ 
							 Соединение цепей между собой`
                    },
                    'Монтажная лента': {
                        linkHere: 'Montazhnaya-lenta',
                        cover: krep32,
                        linkEcabel: 'https://ek-m.com/product/montazhnaya-lenta',
                        description: `Назначение: ~ 
							 Крепление монтажных конструкций или кабельных трасс к потолку или стене`
                    },
                    'Комплекты метизов': {
                        linkHere: 'Komplekty-metizov',
                        cover: krep33,
                        linkEcabel: 'https://ek-m.com/product/komplekty-metizov',
                        description: ''
                    },
                }
            },
        }

    },

    'Стандарт LIGHT': {
        linkHere: 'light',
        cover: light_cover,
        items: {
            'Лотки листовые': {
                linkHere: 'Lotki-listovye',
                cover: l_lot_list1,
                items: {
                    // описание img linkEcabel linkHere
                    'Лотки листовые перфорированные с высотой борта 40/50/65/80/100/110/150/200 мм': {
                        linkHere: 'Lotki-listovye-perforirovannye',
                        cover: l_lot_list1,
                        linkEcabel: 'https://ek-m.com/catalog/lotki-listovye-perforirovannye',
                        description: `Назначение: ~
								Лоток листовой применяется на прямых участках кабельной трассы. Для монтажа используются соединители лотков СЛ. ~
						Технические характеристики: ~
								Тип замка - V-образный ~
								Тип соединения - встык ~
						Исполнение: ~
								Исполнение 1 - Оцинкованная сталь по методу Сендзимира ~
								Исполнение 2 - Горячее цинкование ~
								Исполнение 3 - Нержавеющая сталь (AISI 304, 316) ~
								Исполнение 4 - Полимерно-порошковое покрытие`
                    },
                    'Лотки листовые глухие с высотой борта 40/50/65/80/100/110/150/200 мм': {
                        linkHere: 'Lotki-listovye-gluhie',
                        cover: l_lot_list2,
                        linkEcabel: 'https://ek-m.com/catalog/lotki-listovye-gluhie',
                        description: `Назначение: ~
								Лоток листовой применяется на прямых участках кабельной трассы. Для монтажа используются соединители лотков СЛ. ~
						Технические характеристики: ~
								Тип замка - V-образный ~
								Тип соединения - встык ~
						Исполнение: ~
								Исполнение 1 - Оцинкованная сталь по методу Сендзимира ~
								Исполнение 2 - Горячее цинкование ~
								Исполнение 3 - Нержавеющая сталь (AISI 304, 316) ~
								Исполнение 4 - Полимерно-порошковое покрытие`
                    },
                    'Аксессуары к листовым лоткам': {
                        linkHere: 'Aksessuary-k-listovym-lotkam',
                        cover: l_lot_list3,
                        linkEcabel: 'https://ek-m.com/catalog/aksessuary-k-listovym-lotkam',
                        description: 'Крышки, лотки, повороты, отводы и др.'
                    },
                }
            },
            'Лотки лестничные': {
                linkHere: 'Lotki-lestnichnye',
                cover: l_lot_lest1,
                items: {
                    // описание img linkEcabel linkHere
                    'Лоток лестничный прямой с высотой борта 50/70/100 мм': {
                        linkHere: 'Lotok-lestnichnyj-pryamoj',
                        cover: l_lot_lest1,
                        linkEcabel: 'https://ek-m.com/catalog/lotki-lestnichnye-1',
                        description: `Назначение: ~
								Лоток лестничный прямой применяется для построения прямых участков кабельной трассы. ~
						Технические характеристики: ~
								Тип соединения - встык ~
						Исполнение: ~
								Исполнение 1 - Оцинкованная сталь по методу Сендзимира ~
								Исполнение 2 - Горячее цинкование ~
								Исполнение 3 - Нержавеющая сталь (AISI 304, 316) ~
								Исполнение 4 - Полимерно-порошковое покрытие`
                    },
                    'Аксессуары к лестничным лоткам': {
                        linkHere: 'Aksessuary-k-lestnichnym-lotkam',
                        cover: l_lot_lest2,
                        linkEcabel: 'https://ek-m.com/catalog/aksessuary-k-lestnichnym-lotkam',
                        description: 'Переходники, повороты, лотки, крышки, секции и др.'
                    },
                }
            },
            'Короба кабельные блочные': {
                linkHere: 'Koroba-kabelnye-blochnye',
                cover: koroba1,
                items: {
                    // описание img linkEcabel linkHere
                    'Короб кабельный (блочный/одноканальный/двухканальный/трехканальный)  (прямой/с поворотом вверх на 45°/с поворотом вниз на 45°/с внутренним поворотом на 45°/ с внешним поворотом на 45°)': {
                        linkHere: 'Korob-kabelnyj',
                        cover: koroba1,
                        linkEcabel: 'https://ek-m.com/catalog/koroba-kabelnye',
                        description: `Технические характеристики: ~
								Толщина металла: 1,2 / 1,5 / 2,0 мм ~
								Количество консолей: 3 / 5 шт ~
								Количество стоек: 2 шт ~
								Тип соединения - Встык ~
						Исполнение: ~
								Рама
									Исполнение 1 - Горячее цинкование
									Исполнение 2 - Горячее цинкование
									Исполнение 3 - Грунт-эмаль
									Исполнение 4 - Грунт-эмаль
								Обшивка
									Исполнение 1 - Горячее цинкование
									Исполнение 2 - Оцинкованная сталь
									Исполнение 3 - Оцинкованная сталь
									Исполнение 4 - Грунт-эмаль`
                    },
                    'Аксессуары к кабельным коробам': {
                        linkHere: 'Aksessuary-k-kabelnym-korobam',
                        cover: koroba2,
                        linkEcabel: 'https://ek-m.com/catalog/aksessuary-k-korobam-kabelnym',
                        description: 'Стойки, скобы, подвести, консоли и др.'
                    },
                }
            },
            'Монтажные элементы': {
                linkHere: 'Montazhnye-elementy',
                cover: l_mont1,
                items: {
                    // описание img linkEcabel linkHere
                    'Подвесы': {
                        linkHere: 'Podvesy',
                        cover: l_mont1,
                        linkEcabel: 'https://ek-m.com/catalog/podvesy-1',
                        description: `Назначение: ~
								Используется при монтаже лотков на потолке. Используется в комплекте со шпилькой М8 и подвесом лотка ПЛ3 и ПЛ5. Шпилька и болтовые соединения для крепления поставляются отдельно. ~
						Технические характеристики: ~
								Толщина металла - 2,0 мм ~
						Исполнение: ~
								Исполнение 1 - Оцинкованная сталь по методу Сендзимира ~
								Исполнение 2 - Горячее цинкование ~
								Исполнение 3 - Нержавеющая сталь (AISI 304, 316) ~
								Исполнение 4 - Полимерно-порошковое покрытие`
                    },
                    'Кронштейны': {
                        linkHere: 'Kronshtejny',
                        cover: l_mont2,
                        linkEcabel: 'https://ek-m.com/catalog/kronshteiny',
                        description: `Назначение: ~
								Применяется для потолочного монтажа всех типов кабельных лотков. Шпилька и болтовые соединения для крепления поставляются отдельно. ~
						Технические характеристики: ~
								Толщина металла - 2,0 мм ~
						Исполнение: ~
								Исполнение 1 - Оцинкованная сталь по методу Сендзимира ~
								Исполнение 2 - Горячее цинкование ~
								Исполнение 3 - Нержавеющая сталь (AISI 304, 316) ~
								Исполнение 4 - Полимерно-порошковое покрытие`
                    },
                    'Соединительные элементы': {
                        linkHere: 'Soedinitelnye elementy',
                        cover: l_mont3,
                        linkEcabel: 'https://ek-m.com/catalog/soedinitelnye-elementy-1',
                        description: `Исполнение: ~
								Исполнение 1 - Оцинкованная сталь по методу Сендзимира ~
								Исполнение 2 - Горячее цинкование ~
								Исполнение 3 - Нержавеющая сталь (AISI 304, 316) ~
								Исполнение 4 - Полимерно-порошковое покрытие`
                    },
                    'Стойки напольные': {
                        linkHere: 'Stojki napolnye',
                        cover: l_mont4,
                        linkEcabel: 'https://ek-m.com/catalog/stoiki-napolnye',
                        description: `Назначение: ~
								Напольная установка группы аппаратов. Используется с С-образным профилем, соединяющим 2 и более стоек в единую конструкцию ~
						Технические характеристики: ~
								Толщина металла - 2,0 мм ~
						Исполнение: ~
								Исполнение 1 - Оцинкованная сталь по методу Сендзимира ~
								Исполнение 4 - Полимерно-порошковое покрытие`
                    },
                    'Болты': {
                        linkHere: 'Bolty',
                        cover: l_mont5,
                        linkEcabel: 'https://ek-m.com/catalog/bolty',
                        description: `Назначение: ~
								Соединение монтажных элементов между собой ~
						      Крепление монтажных конструкций к стенам, потолку или полу (совместно с анкерами) `
                    },
                    'Винты': {
                        linkHere: 'Vinty',
                        cover: l_mont6,
                        linkEcabel: 'https://ek-m.com/catalog/vinty',
                        description: `Назначение: ~
								Соединение монтажных элементов между собой`
                    },
                    'Гайки': {
                        linkHere: 'Gajki',
                        cover: l_mont7,
                        linkEcabel: 'https://ek-m.com/catalog/gaiki',
                        description: `Назначение: ~
								Соединение монтажных элементов между собой ~
								Крепление кабельных лотков к монтажным аксессуарам`
                    },
                    'Анкера': {
                        linkHere: 'Ankera',
                        cover: l_mont8,
                        linkEcabel: 'https://ek-m.com/catalog/ankera',
                        description: `Назначение: ~
								Крепление тяжеловесных конструкций к бетону, природному камню или полнотелому кирпичу`
                    },
                }
            },
            'Метрический крепеж': {
                linkHere: 'Metricheskij-krepezh',
                cover: metr_krep1,
                items: {
                    // описание img linkEcabel linkHere
                    'Винт с крестообразным шлицем и квадратным  подголовником': {
                        linkHere: 'Vint-s-krestoobraznym-shlicem-i-kvadratnym-podgolovnikom',
                        cover: metr_krep1,
                        linkEcabel: 'https://ek-m.com/product/vint-s-krestoobraznym-shlicem-i-kvadratnym-podgolovnikom-1',
                        description: `Назначение: ~
								Соединение листовых или лестничных лотков между собой ~
								Крепление лотков к монтажным аксессуарам.`
                    },
                    'Винт с полукруглой головкой': {
                        linkHere: 'Vint-s-polukrugloj-golovkoj',
                        cover: metr_krep2,
                        linkEcabel: 'https://ek-m.com/product/vint-s-polukrugloi-golovkoi-1',
                        description: `Назначение: ~
								Соединение монтажных элементов между собой`
                    },
                    'Болт с шестигранной головкой с частичной резьбой': {
                        linkHere: 'Bolt-s-shestigrannoj-golovkoj-s-chastichnoj-rezboj',
                        cover: metr_krep3,
                        linkEcabel: 'https://ek-m.com/product/bolt-s-shestigrannoi-golovkoi-s-chastichnoi-rezboi-1',
                        description: `Назначение: ~
								Болт М8х60 - соединение потолочных креплений СВ-02.2901, СВ-02.2902 и консолей БЛ-02.40, БМ-02.40, БСЛ-02.40, БСМ-02.40, БСЛ-02.45 с профилями ПМ-02.29, ПХ-02.29 ~ 
								Болт М8х70 - соединение потолочного крепления СХИ-02.4101 с профилями ПМ-02.29, ПХ-02.29 `
                    },
                    'Болт с шестигранной головкой': {
                        linkHere: 'Bolt-s-shestigrannoj-golovkoj',
                        cover: metr_krep4,
                        linkEcabel: 'https://ek-m.com/product/bolt-s-shestigrannoi-golovkoi-1',
                        description: `Назначение: ~
								Соединение монтажных элементов между собой ~ 
								Крепление монтажных конструкций к стенам, потолку или полу (совместно с анкерами)`
                    },
                    'Шпилька резьбовая': {
                        linkHere: 'SHpilka-rezbovaya',
                        cover: metr_krep5,
                        linkEcabel: 'https://ek-m.com/product/shpilka-rezbovaya-1',
                        description: ` Назначение: ~
								Длина l до 1000 мм - крепление трубных хомутов к профилям и консолям, соединение монтажных аксессуаров между собой ~
								Длина l более 1000 мм - подвес кабеленесущих трасс к потолку`
                    },
                    'Шпилька-шуруп': {
                        linkHere: 'SHpilka-shurup',
                        cover: metr_krep6,
                        linkEcabel: 'https://ek-m.com/product/shpilka-shurup',
                        description: `Назначение: ~
								Крепление монтажных конструкций`
                    },
                }
            },
        }
    }



}

export { cabelSist }