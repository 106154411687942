import virtek from '../../../assets/images/common/virtek.svg'
import c from './footer.module.scss'

const now = new Date()

const Footer = () => {

    return (
        <footer className={c.footer}>
            <div className='footer__container'>
                <div className={c.inner}>
                    <div className={c.left}>
                        {`©  ООО «ЭКМ Трейд», ${now.getFullYear()} `} <br />
                        {window.location.origin}
                    </div>
                    <div className={c.right}>
                        <a href='https://virtek.pro/' target='_blank' >
                            <span>Создание сайта - </span>
                            <img src={virtek} alt='VirTek'/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export { Footer }