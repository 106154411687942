import React from 'react'
import c from './about.module.scss'


const About = ({ hash }) => {
    const aboutRef = React.useRef()
    if (hash == '#aboutUs') {
        aboutRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', })
    }
    return (
        <section className={`${c.about} section`} id='aboutUs' ref={aboutRef}>
            <div className='about__container'>
                <h2>
                    О компании
                </h2>
                <div className={c.text}>
                    <p>
                        ООО «ЭКМ Трейд» - оператор поставок электротехнической продукции для предприятий промышленности, гражданского строительства, машиностроения, транспортной инфраструктуры.
                    </p>
                    <p>
                        Компания является официальным дилером ООО НПП «ИНТЕХ» (г. Уфа) - разработчика и производителя серии современных монтажных (до 1000 В), силовых (до от 6 до 35 кВ) и термоэлектродных кабелей запатентованной марки «ИнСил», монтажных кабелей «КуПе» для опасных, в том числе взрывоопасных производственных объектов в различных отраслях и отвечает всем предъявляемым требованиям и качеству.
                    </p>
                    <p>
                        Продукция производится на заводе в. Софрино Московская обл., РФ по современным технологиям с использованием нового оборудования ведущих зарубежных производителей и применением экологически безопасных материалов. Вся производимая линейка продукции сертифицирована в области промышленной безопасности, пожарной безопасности и низковольтному оборудованию, сертифицирована в системе добровольной сертификации ИНТЕРГАЗСЕРТ, имеется лицензия федеральной службы по экологическому, технологическому и атомному надзору, одобрена Российским морским регистром судоходства.
                    </p>
                    <p>
                        ООО «ЭКМ Трейд» является официальным дилером компании ООО «ЭКМ Холдинг» по производству кабеленесущих систем. Номенклатура представлена широким ассортиментом кабельных лотков и коробов, перфошвеллеров, лестничных лотков и фасонных изделий. Предприятие имеет современную производственную площадку в г. Екатеринбург, позволяющую осуществлять полный технологический цикл производства, а также собственную сырьевую базу тонколистного металлопроката. Вся продукция изготавливается из оцинкованной и хладокатаной стали от ведущих металлургических заводов России. Гибкая логистика, подъездные ж/д пути позволяют организовать доставку готовой продукции в любую точку Российской Федерации.
                    </p>
                    <p>
                        Компания обеспечивает полный цикл услуг по обустройству кабельных трасс, осуществляя поставку кабельно-проводниковой продукции и кабеленесущих конструкций.
                    </p>
                </div>
            </div>
        </section>
    )
}

export { About }